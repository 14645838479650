import { default as axios } from "../axiosInstance";

interface Prescription {
  id: string;
  action: string;
}

interface ShowResponse {
  data: Prescription;
}

export async function show(id: string, action: string): Promise<{ prescription: Prescription }> {
  console.log('inside method', id, action);
  
  let prescription: Prescription | {} = {};
  try {

    const response: ShowResponse = await axios.get(`/prescriptions/${id}`, {
      params: { action: action },
    });
    
    prescription = response.data;

  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error;
  }

  return { prescription: prescription as Prescription } 
}