<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SwitchToggle',
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    label: {
      type: String as PropType<string>,
      default: ''
    },
    switchId: {
      type: String as PropType<string>,
      default: 'switchToggle'
    }
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    toggleSwitch(event: Event) {
      const target = event.target as HTMLInputElement;
      const isChecked = target.checked;
      
      this.$emit('update:modelValue', isChecked);

      this.$emit('change', isChecked);
    }
  }
});
</script>

<template>
  <div class="form-check form-switch form-check-reverse custom-switch">
    <input 
      class="form-check-input" 
      type="checkbox" 
      :id="switchId"
      :checked="modelValue"
      @change="toggleSwitch"
    >
    <label class="form-check-label" :for="switchId">{{ label }}</label>
  </div>
</template>

<style scoped>
.custom-switch {
  line-height: inherit;
}
.custom-switch .form-check-input:checked {
  background-color: #A58ABA;
  border-color: #A58ABA;
}

.custom-switch .form-check-input:focus {
  box-shadow: none;
}

.custom-switch .form-check-input {
  background-color: #e9ecef;
  border-color: #adb5bd;
  box-shadow: none;
}
</style>