<script>
import { ref, watch } from "vue";

export default {
  name: "SlidingPanel",
  props: {
    show: { type: Boolean, default: false },
  },
  setup(props) {
    const isShown = ref(props.show);

    watch(() => props.show, value => isShown.value = value);
    
    return { isShown };
  },
};
</script>

<template>
  <Transition name="slide-fade">
    <!-- Exctract style  -->
    <div v-if="isShown"  class="position-absolute bg-light w-100">
      <slot></slot>
    </div>
  </Transition> 
</template>

<style scoped>
.slide-fade-leave-active,
.slide-fade-enter-active {
  transition: all 0.5s ease; /* Durata della transizione uniforme */
}

.slide-fade-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
