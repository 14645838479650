import { ref, onMounted, onUnmounted, provide, Ref } from 'vue';

export function useLayoutObserver(containerElement: Ref<HTMLElement | null>) {
  const mobileLayout = ref<boolean>(false);
  const onlyFullScreen = ref<boolean>(false);

  const getLayoutType = (observedElement: ResizeObserverEntry) => {
    if (observedElement && observedElement.contentRect) {
      mobileLayout.value = observedElement.contentRect.width <= 991.98;
    }
  };

  const updateFullScreenLayout = () => {
    onlyFullScreen.value = window.innerWidth < 768 || window.innerHeight < 700;
  }

  let resizeObserver: ResizeObserver | null = null;

  onMounted(() => {
    resizeObserver = new ResizeObserver((observedElements: ResizeObserverEntry[]) => {
      const observedElement = observedElements[0];
      getLayoutType(observedElement);
    });

    if (containerElement.value) {
      resizeObserver.observe(containerElement.value);
    }

    updateFullScreenLayout();
    window.addEventListener('resize', updateFullScreenLayout);
  });

  onUnmounted(() => { 
    if (resizeObserver && containerElement.value) {
      resizeObserver.unobserve(containerElement.value);
    }

    window.removeEventListener('resize', updateFullScreenLayout);
  });

  provide('mobileLayout', mobileLayout);
  provide('onlyFullScreen', onlyFullScreen);

  return { mobileLayout, onlyFullScreen };
}
