import { default as axios } from "../axiosInstance";

// Define the type for the response structure
interface ShowResponse<T> {
  data: T;
}

// Define the structure of the result that the function will return
interface ShowResult<T> {
  message: T;
}

// Define the parameters and their types for the `show` function
interface ShowParams {
  id: string; // Assuming 'id' is a string. Adjust if 'id' is a different type.
  action: string; // Assuming 'action' is a string. Adjust based on your needs.
}

/**
 * Function to fetch a specific message by ID and action.
 * @param id - The ID of the message to fetch.
 * @param action - The action to perform.
 * @param loader - Optional boolean to indicate loading state.
 * @returns A promise resolving to an object containing the message data.
 */
export async function show<T = any>(
  id: string,
  action: string,
  loader: boolean = false,
): Promise<ShowResult<T>> {
  let message: T;

  try {
    const response: ShowResponse<T> = await axios.get(`/messages/${id}`, {
      params: { action }, // Use `params` to properly pass query parameters
    });
    message = response.data;
  } catch (error: any) {
    // If more specific error handling is needed, adjust as necessary.
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the full error if the expected structure is not found.
  }

  return { message };
}
