<script>
import { inject } from "vue";
import { ListItem, Spinner } from "@/components";
import WindowActions from "./WindowActions.vue";
import { useIntersectionObserver } from "../composables/useIntersectionObserver";

export default {
  name: "ConversationsControl",
  components: { ListItem, Spinner, WindowActions },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    search: { type: String },
    spinner: { type: Boolean },
  },
  emits: ["update:search", "select-conversation", "selectChatType"],
  setup(props, { emit }) {
    const mobileLayout = inject("mobileLayout");
    const activeChannel = inject("activeChannel");
    const showList = inject('showList')
    const user = inject('user');
    const onUserClick = inject('onUserClick');
    const loadMore = inject('loadMore');

    const { target } = useIntersectionObserver(() => loadMore());

    const handleClick = (channel) => {
      emit('select-conversation', channel);
      onUserClick();
    }

    return { 
      mobileLayout,
      activeChannel,
      showList,
      handleClick,
      user,
      target,
    };
  },
};
</script>
<template>
<transition name="slide-left">
  <div
    v-show="showList"
    class="h-100"
    :class="{ 'w-100': mobileLayout, 'desktop-width': ! mobileLayout }"

  >
    <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-light h-100">
      <span class="p-4 h-8">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-end">
            <img src="@/assets/icons/logo.svg" alt="app-logo" width="90" class="ms-2 me-3">
            <h5 class="text-muted m-0">{{ user.first_name }} {{ user.last_name }}</h5>
          </div>
          <WindowActions v-if="mobileLayout" />
        </div>
        <form class="m-2" @submit.prevent>
          <SearchBar
          @update:search="(value) => $emit('update:search', value)"
          :placeholder="$t('Search patient or prescription')"
          />
        </form>
      </span>
      <TabNavigation @select-chat-type="(chatType, chatSubType, value) => $emit('selectChatType', chatType, chatSubType, value)" />
      
      <div
        class="list-group list-group-flush border-bottom px-3 scrollarea overflow-auto"
        :class="{
          'min-with-30': ! mobileLayout,
          'scrollarea-mobile': mobileLayout,
          'scrollarea': !mobileLayout
        }"
      >
        <template v-if="$props.channels.length">
          <ListItem
            v-for="(channel, index) in $props.channels"
            :key="index"
            :channel="channel"
            @click="handleClick(channel)"
          />
        </template>
        <template v-else-if="$props.spinner">
          <Spinner  class="m-auto"/>
        </template>
        <template v-else>
          <p class="m-auto">Nessuna chat trovata</p>
        </template>
        <template v-if="$props.spinner && $props.channels.length">
          <Spinner class="m-auto my-2 "/>
        </template>

        <div ref="target" class="sentinel"></div>
      </div>
    </div>
  </div>
</transition>
</template>

<style lang="scss" scoped>
.slide-left-enter-active, .slide-left-leave-active {
  transition: transform 0.5s ease;
}
.slide-left-enter {
  transform: translateX(-50%);
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}
.h-8 {
  height: 8rem;
}
.h-9 {
  height: 9rem;
}
.scrollarea {
  height: calc(100% - 13rem);
}
.scrollarea-mobile {
  min-width: 22rem;
  height: calc(100% - 14rem);
}
.min-with-30 {
  min-width: 30rem;
} 
.close-modal > img {
  filter: invert(0.75);
}
.sentinel {
  border: 1px solid transparent;
  margin: 1.5rem;
}
.desktop-width {
  width: 40rem;
}
</style>
