import { defineStore } from "pinia";
import { ref } from "vue";

interface User {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  authToken: string | undefined;
  totalUnreadMessages: number;
}

export const useMainStore = defineStore("main", () => {
  // State
  const loading = ref<boolean>(false);
  const user = ref<User>({
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    authToken: undefined,
    totalUnreadMessages: 0,
  });
  const badge = ref<boolean>(false);

  // Actions
  const setUser = (newUser: User) => {
    user.value = newUser;
  };

  const toggleLoading = (isLoading: boolean) => {
    loading.value = isLoading;
  };

  const setUserPersonalMessagesUnread = (amount: number) => {
    user.value.totalUnreadMessages = amount;
  };

  const increasePersonalMessagesCount = (amount: number) => {
    user.value.totalUnreadMessages += amount;
  };

  const decreasePersonalMessagesCount = (amount: number) => {
    user.value.totalUnreadMessages -= amount;
  };

  const showApp = ref<boolean>(false);
  const expanded = ref<boolean>(false);

  return {
    loading,
    user,
    badge,
    setUser,
    toggleLoading,
    setUserPersonalMessagesUnread,
    increasePersonalMessagesCount,
    decreasePersonalMessagesCount,
    showApp,
    expanded
  };
});
