import { App } from "vue"; // Import the type for Vue app instance
import Pusher, { Options } from "pusher-js"; // Import Pusher and its Options type

// Define an interface for the plugin options
interface PusherPluginOptions extends Options {
  apiKey: string; // Ensure that apiKey is a required string property
}

// Default export as a function that registers the plugin
export default (app: App, options: PusherPluginOptions): void => {
  const { apiKey, ...pusherOptions } = options;

  // Initialize Pusher and attach it to the global properties of the Vue instance
  app.config.globalProperties.$pusher = new Pusher(apiKey, pusherOptions);
};
