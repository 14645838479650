import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import pusherPlugin from "./pusher";
import { authorize } from "./pusher/authorize";
import it from "../translations/it.json";

export const pluginsWrapper = {
  install(GivenVue: any) {
    const Vue = GivenVue;

    // Pinia
    const pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);
    Vue.use(pinia);

    // Vue I18n
    const i18n = createI18n({
      locale: "it",
      messages: {
        it,
      },
      fallbackLocale: "it",
    });
    Vue.use(i18n);

    // Pusher
    Vue.use(pusherPlugin, {
      apiKey: import.meta.env.VITE_PUSHER_KEY,
      cluster: "eu",
      authorizer: authorize,
    });
  },
};
