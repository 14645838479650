<script lang="ts">
import { ref, onMounted } from "vue";
import { show as showChannel } from "@/use/repositories/chat/channel/show";

interface Message {
  media: any[];
}

interface Channel {
  chat_channel: { id: string };
}

export default {
  props: {
    channel: {
      type: Object as () => Channel,
      required: true,
    },
  },
  setup(props) {
    const onLoading = ref<boolean>(false);

    const dataSources = ref<object[] | null>(null);

    onMounted(async () => {
      try {
        onLoading.value = true;

        const { channel: { data: { messages } } } = await showChannel(props.channel.chat_channel.id, "with_media");

        const allMedia = [...new Set(messages.flatMap((message: Message) =>
          message.media.map((media) => ({ ...media, body: message.body, created_at: message.created_at, user: message.user }))
        ))];

        dataSources.value = allMedia;
      } catch (error) {
        console.error(error);
      } finally {
        onLoading.value = false;
      }
    });

    return { dataSources, onLoading };
  },
};
</script>

<template>
  <ImageRender v-if="! onLoading && dataSources?.length" :imagesData="dataSources" viewMode="gallery" />
  <h5 v-else-if="! onLoading" class="text-dark-violet text-center m-5">{{ $t("No media found") }}</h5>
</template>
