import { default as axios } from "../axiosInstance";

// Define the structure of the expected API response
interface UpdateResponse<T> {
  data: T;
}

// Define the structure of the result that the function will return
interface UpdateResult<T> {
  data: T;
}

// Define a generic type for the function's parameter
type ParamsType = Record<string, any>;

/**
 * Function to update a message using the provided parameters.
 * @param params - The parameters to be sent in the request body for the update. This is a generic object.
 * @returns A promise resolving to an object containing the updated message data.
 */
export async function update<T = any>(
  params: ParamsType,
): Promise<UpdateResult<T>> {
  let data: T;

  try {
    const response: UpdateResponse<T> = await axios.put("/messages", params);
    data = response.data;
  } catch (error: any) {
    // Check if error response has the expected structure and handle accordingly
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the full error if the expected structure is not found
  }

  return { data };
}
