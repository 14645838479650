import { default as axios } from "../axiosInstance";

// Define the structure of the response from the API.
interface IndexResponse<T> {
  data: T; // Assuming the data structure can vary, using a generic type T.
}

// Define the structure of the return value of the function.
interface IndexResult<T> {
  data: T;
}

// Define the parameters type, if more specifics are known, these can be detailed.
interface IndexParams {
  [key: string]: any; // Replace 'any' with specific types for known params
}

/**
 * Function to fetch all messages with given parameters.
 * @param params - The query parameters to pass with the request.
 * @param loader - An optional boolean to indicate if a loading state should be shown.
 * @returns A promise resolving to an object containing the fetched data.
 */
export async function index<T = any>(
  params: IndexParams,
  loader: boolean = false,
): Promise<IndexResult<T>> {
  let data: T;

  try {
    const response: IndexResponse<T> = await axios.get("/messages", {
      params,
    });
    data = response.data;
  } catch (error: any) {
    // Using 'any' for the error type. Adjust it based on actual error structure if available.
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the entire error if the expected structure is not found.
  }

  return { data };
}
