<script>
import { inject, computed, nextTick, reactive, ref, watch } from "vue";
import { Header, Body } from "@/components";

import dayjs from "dayjs";
import _ from "lodash";

import { index as fetchMessages } from "@/use/repositories/chat/message/index";
import { show as showMessage } from "@/use/repositories/chat/message/show";
import { store as storeMessageChat } from "@/use/repositories/chat/message/store";

import { useMainStore } from "../pinia/main.store";
import MultiUploadWrapper from "./upload/MultiUploadWrapper.vue";

export default {
  name: "Conversation",
  components: {
    Header,
    Body,
    MultiUploadWrapper,
  },
  emits: ["toFavorite", "showDetails"],
  setup() {
    const store = useMainStore();
    const bodyChat = ref(null);
    const mobileLayout = inject("mobileLayout");
    const channel = inject("activeChannel");
    const pusherEvent = inject("pusherEvent");
    const renderChatBodyKey = ref(0);

    const authUser = inject('user');
    const canUseChat = ref(true);

    const message = ref("");
    const sendingMessage = ref(false);

    const messagesGroupedByDate = ref([]);
    const page = ref(1);
    const totalPages = ref(1);
    const isHistoryMessages = ref(false);
    const loading = ref(true);
    const conversation = ref(null);
    const onTheBottom = ref(null);
    const badgeNewMessageArrived = ref(false);
    const messageArrivedIsMine = ref(true);
    const debug = ref(false);
    const haveScroll = ref(null);
    const conversationFooter = ref(null);
    const conversationContainer = ref(null);
    const multiUploadWrapper = ref();
    const messageInput = ref(null);
    const showMultiUpload = ref(false);
    const showConversation = inject('showConversation')
    const mediaCounter = ref(0);
    const markAsRead = inject('markAsRead');

    const existsHistory = computed(() => page.value < totalPages.value && totalPages.value > 1);

    const toggleMultiUpload = () => showMultiUpload.value = !showMultiUpload.value;

    const handlePusherEvent = (event) => {
      switch (event.eventName) {
        case "pusher:subscription_succeeded":
          console.log("subscription succeeded");
          break;
        case "messaging":
          messageArrivedIsMine.value = event.data.user_id === store.user.id;
          if (channel.chat_channel.id === event.data.chat_channel_id) {
            isHistoryMessages.value = false;
            handleArrivedMessage(event.data.id);
          }
          break;
      }

      console.log(`bind global channel: The event ${event.eventName} was triggered with data ${JSON.stringify(event.data)}`);
    };

    const updateComponent = () => {
      enabledInputMessage();
      renderChatBodyKey.value = renderChatBodyKey.value + 1;
    };

    const handleChatChannel = () => {
      console.log(`CHANNEL ID: ${channel.chat_channel.id}`);
      if (channel.chat_channel.id) {
        getMessages(channel.chat_channel.id, false, true);
      }
    };

    const getMessages = async (id, history = false, getMore = false) => {
      fetchMessages(
        {
          action: "by_channel",
          channel_id: id,
          page: page.value,
        },
        true,
      ).then((response) => {
        if (history) {
          response.data.data.forEach((message) => syncMessage(message, history));
        } else {
          messagesGroupedByDate.value = _(response.data.data)
            .map((value) => {
              return {
                ...transformDataMessage(value),
                date: dayjs(value.created_at).format("DD/MM/YYYY"),
              };
            })
            .groupBy("date")
            .map((value, key) => ({
              date: key,
              messages: _.chain(value).sortBy("created_at").value().reverse(),
            }))
            .value()
            .reverse();
        }

        loading.value = false;

        totalPages.value = response.data.meta.last_page;

        // Request more messages for the first interaction
        if (getMore) getHistory();
      });
    };

    const transformDataMessage = (message) => {
      return {
        id: message.id,
        first_name: message.user.first_name,
        last_name: message.user.last_name,
        channel_message_type: message.message_type.slug,
        channel_message_color: message.message_type.color,
        is_sender: store.user.id === message.user_id,
        time: dayjs(message.created_at).format("HH:mm"),
        text: renderMessage(message.body),
        attachments: getThumbnails(message),
        role: message?.user_role?.name,
        chat_channel_id: channel.chat_channel.id,
        is_placeholder: message.is_placeholder ? true : false
      };
    };

    const renderMessage = (string) => string.replace(String.fromCharCode(92), String.fromCharCode("", ""));

    const handleArrivedMessage = async (messageId) => {

      const { message: { data } } = await showMessage(messageId);

      const chatWithPlaceholder = _.find(messagesGroupedByDate.value, chatWithPlaceholder =>
        _.some(chatWithPlaceholder.messages, message => message.id === messageId)
      );

      if (chatWithPlaceholder) {
        _.remove(chatWithPlaceholder.messages, message => message.id === messageId);
      }
      
      syncMessage(data);

      if (messageArrivedIsMine.value) {
        markAsRead(data.chat_channel_id, messageId);
      }
    };

    const syncMessage = (data, history = false) => {
      let createdAt = dayjs(data.created_at).format("DD/MM/YYYY");
      let formattedMessage = transformDataMessage(data);
      // Find date and push.
      let index = messagesGroupedByDate.value.findIndex((item) => item.date === createdAt);

      if (index === -1) {

        const data = { date: createdAt, messages: [formattedMessage] };
        // If the date doesn't exist create the group and push message.
        if (history) messagesGroupedByDate.value.unshift(data);
        else messagesGroupedByDate.value.push(data);

      } else {
        // If date exist push message.
        if (history) messagesGroupedByDate.value[index].messages.unshift(formattedMessage);
        else messagesGroupedByDate.value[index].messages.push(formattedMessage);
      }

      loading.value = false;
      enabledInputMessage();
    };

    const getPositionMessage = message => message.is_sender ? "end" : "start";

    const getThumbnails = (message) => {
      return message.media.map((media) => ({ ...media, body: message.body, created_at: message.created_at, user: message.user }))
    };
    const storeMessage = async () => {
      loading.value = true;
      disabledInputMessage();
      bodyChat.value?.scrollToBottom();
      save();
    };

    const save = async () => {
      // Store with media.
      if (multiUploadWrapper.value && mediaCounter.value) multiUploadWrapper.value.upload();
      else if (message.value.trim().length !== 0) {
        try {
          const formData = createFormData();
          const { message: { data } } = await storeMessageChat(formData);
          clearMessageField();
          const placeholderMessage = createPlaceholderMsg(formData.get('body'), data);
          syncMessage(placeholderMessage);
          bodyChat.value?.scrollToBottom();
        } catch (error) {
          console.error('Error', error);
        }
      }
    };

    const createPlaceholderMsg = (body, message) => {
      return {
        id: message.id,
        user_id: authUser.id,
        user: { first_name: authUser.first_name, last_name: authUser.last_name },
        message_type: { slug: "default", color: "#e9e9e9" },
        created_at: dayjs(),
        body: body,
        media: [],
        chat_channel_id: message.chat_channel_id,
        is_placeholder: true
      };
    };

    const setFocusOnInputMessage = () => {
      nextTick(() => {
        if (messageInput.value) messageInput.value.focus();
      });
    };

    const clearMessageField = () => message.value = "";

    const enabledInputMessage = () => {
      sendingMessage.value = false;
      setFocusOnInputMessage();
    };

    const disabledInputMessage = () => {
      sendingMessage.value = true;
    };

    const createFormData = () => {
      let formData = new FormData();
      formData.append("chat_channel_id", channel.chat_channel.id);
      formData.append("type_slug", "default");
      formData.append("message_subcategory_slug", "free-message");
      formData.append("body", message.value);
      formData.append("action", "without_media");
      return formData;
    };

    const getHistory = () => {
      console.log('get history log');

      if (existsHistory.value) {
        isHistoryMessages.value = true;
        page.value++;
        if (channel.chat_channel.id) {
          getMessages(channel.chat_channel.id, true);
        }
      }
    };

    const isSendButtonDisabled = computed(() => ! channel.prescription_id || sendingMessage.value || (message.value.trim().length === 0 && ! mediaCounter.value));

    const onUploadCompleted = () => {
      toggleMultiUpload();
      clearMessageField();
      bodyChat.value?.scrollToBottom();
    }

    const handleKeypress = (event) => {
      if (event.key === 'Enter' && !event.ctrlKey) {
        event.preventDefault();

        if (message.value.trim().length !== 0) return storeMessage();
      }

      if (event.key === 'Enter' && event.ctrlKey) message.value += '\n';
    }

    watch(
      () => showMultiUpload.value,
      (value) => {
        if (! value)  mediaCounter.value = 0
      }
    );

    watch(
      () => pusherEvent,
      newEvent => handlePusherEvent(newEvent),
      { deep: true },
    );

    watch(
      () => channel.prescription_id,
      () => {
        page.value = 1;
        messagesGroupedByDate.value = [];
        updateComponent();
        // Verify chat channel and fetch messages.
        handleChatChannel();
      },
    );

    watch(
      () => messagesGroupedByDate.value,
      () => {
        if (onTheBottom.value) bodyChat.value.scrollToBottom();
        else {
          if (messageArrivedIsMine.value && !isHistoryMessages.value) {
            // Message arrived is mine and scroll to bottom.
            bodyChat.value?.scrollToBottom();
          } else if (!isHistoryMessages.value) {
            // Message arrived is not mine and show notify.
            badgeNewMessageArrived.value = true;
          }
        }
      },
      { deep: true },
    );

    return {
      mobileLayout,
      channel,
      getPositionMessage,
      getThumbnails,
      message,
      messagesGroupedByDate,
      loading,
      storeMessage,
      conversation,
      totalPages,
      onTheBottom,
      badgeNewMessageArrived,
      page,
      debug,
      haveScroll,
      getHistory,
      unescape,
      sendingMessage,
      messageInput,
      conversationFooter,
      conversationContainer,
      canUseChat,
      renderChatBodyKey,
      bodyChat,
      showConversation,
      toggleMultiUpload,
      showMultiUpload,
      multiUploadWrapper,
      mediaCounter,
      onUploadCompleted,
      isSendButtonDisabled,
      handleKeypress,
    };
  },
};
</script>
<template>
  <transition name="slide-right">
    <div v-if="showConversation" class="h-100 w-100" :class="{ 'w-100': mobileLayout }">
      <div ref="conversationContainer" class="d-flex flex-column flex-grow-1"
        :class="{ mobile: mobileLayout, 'h-100': !mobileLayout }">
        <Header 
          @to-favorite="(value) => $emit('toFavorite', value)"
          @show-details="(value) => $emit('showDetails', value)"
          :show-details="channel.showDetails" />
          <ChannelDetailsTabs v-if="channel.showDetails" />
          <template v-else>
            <Body ref="bodyChat" :key="renderChatBodyKey" :chat="messagesGroupedByDate" :spinner="loading" @get-history="getHistory" />
          <div ref="conversationFooter" header
              class="conversation__footer position-relative"
              :class="{ 'pb-4': mobileLayout }"
              >
              <SlidingPanel :show="showMultiUpload" :style="{ bottom: mobileLayout ? '5.5rem' : '4.5rem' }">
                <MultiUploadWrapper ref="multiUploadWrapper" :channelId="channel.chat_channel.id" :textMessage="message"
                  v-model:mediaCounter="mediaCounter" @uploadCompleted="onUploadCompleted" />
              </SlidingPanel>
              <div v-if="channel.prescription_id"
                class="conversation__footer__actions w-100 h-100 d-flex justify-content-evenly align-items-center">
                <button :disabled="! channel.prescription_id" class="btn" @click="toggleMultiUpload">
                  <img src="@/assets/icons/paperClip.svg" alt="paperclip" width="30" />
                </button>
                <textarea 
                  ref="messageInput" 
                  @keydown="handleKeypress($event)"
                  v-model="message" 
                  :disabled="! channel.prescription_id || ! canUseChat || sendingMessage"
                  type="text" 
                  autocomplete="off" 
                  class="form-control"
                  :placeholder="$t('Write a message')"
                  />
                <button class="btn h-100 border-0" :disabled="isSendButtonDisabled"
                  :class="{ opacity: message === '' }" @click="storeMessage()">
                  <img src="@/assets/icons/sendFill.svg" alt="send" width="30" />
                </button>
              </div>
            </div>
          </template>
      </div>
    </div>
  </transition>
  <template v-if="!mobileLayout"> </template>
</template>
<style scoped>
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(100%);
}

.w-fit-content {
  width: fit-content;
}

.conversation__footer {
  padding: 0.5rem;
  background-color: #f5f5f5;
}

.conversation__footer__actions>input {
  margin: 0 10px;
  border-radius: 10px;
}

.mobile {
  height: calc(100%);
}

textarea {
  resize: none;
}

button:disabled {
  cursor: not-allowed;
}
</style>
