<script>
import { inject } from "vue";
import { show as showPrescription } from "@/use/repositories/chat/prescriptions/show";
import WindowActions from "./WindowActions.vue";

export default {
  name: "Header",
  emits: ["toFavorite"],
  components: {
    WindowActions,
  },
  setup() {
    const mobileLayout = inject("mobileLayout");
    const channel = inject("activeChannel");
    const goBackToList = inject('goBackToList');

    const showPrescriptionLink = async (prescriptionId) => {
      const { prescription: { data } } = await showPrescription(prescriptionId, 'extra_info_url');

      return window.open(data, '_blank');
    };

    return { mobileLayout, channel, showPrescriptionLink, goBackToList };
  },
};
</script>
<template>
  <nav class="bg-light">
      <div 
        v-if="mobileLayout"
        class="d-flex align-items-center justify-content-between w-100">
        <button v-if="mobileLayout" @click="goBackToList" class="btn" type="button">
          <img src="@/assets/icons/arrow-left.svg" width="60" alt="arrow-left" class="cp arrow-left" />
        </button>
        <a @click.prevent="showPrescriptionLink(channel.prescription_id)" class="text-decoration-none fw-bolder text-violet text-nowrap cp fs-4">
          <img src="@/assets/icons/redirect.svg" alt="redirect" width="20" class="align-baseline">
          {{ channel.name }}
        </a>
        <WindowActions />
      </div>
      <div v-if="channel.name" class="d-flex align-items-center justify-content-between w-100">
        <div class="text-dark p-3">
          <span class="chat-description me-3 fs-5 text-capitalize" aria-current="page">{{ channel.description }}</span>
          <a  
            v-if="! mobileLayout" 
            @click.prevent="showPrescriptionLink(channel.prescription_id)"
            class="text-decoration-none fw-bolder text-violet text-nowrap cp"
          >
            <img src="@/assets/icons/redirect.svg" alt="redirect" width="20" class="align-baseline">
            {{ channel.name }}
          </a>
        </div>
        <div class="d-flex flex-nowrap">
          <button
            v-if="channel.is_favorite !== null"
            class="btn"
            type="button"
            @click="$emit('toFavorite', channel.chat_channel)"
          >
            <img v-if="channel.is_favorite" src="@/assets/icons/heart-selected.svg" width="35" alt="arrow-left" class="cp" />
            <img v-else src="@/assets/icons/heart.svg" width="35" alt="arrow-left" class="cp" />
          </button>
          <WindowActions v-if="! mobileLayout"/>
        </div>
      </div>
  </nav>
</template>
<style lang="scss" scoped>
img.arrow-left,
img.close-modal {
  filter: invert(0.5);
}
.close-modal > img {
  filter: invert(0.75);
}
.chat-title {
    text-transform: lowercase;
}
.chat-title:first-letter {
    text-transform: uppercase;
}
</style>
