<script setup lang="ts">
import { computed, onMounted, onUnmounted, Ref, ref } from 'vue';
import PhotoSwipeLightbox from 'photoswipe/lightbox';


const isMounted = ref(false);

onMounted(() => {
    isMounted.value = true;
});

const containerPhotoswiper = ref(null);

const lightbox: Ref<PhotoSwipeLightbox | null> = ref(null);

const props = defineProps({
    imagesData: {
        type: Array,
        required: true,
    }
});

const images = computed(() => props.imagesData.map((url) => {
    return {
        "src": url,
        "largeURL": url,
    };
}));

const openGallery = async () => {
    lightbox.value?.loadAndOpen(0);
};

const openGalleryIndex = async (index: number) => {
    lightbox.value?.loadAndOpen(index);
};

const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve({ width: img.width, height: img.height });
  });
}

const createBlobURL = async (link: Link): string => {
  const response = await fetch(link);
  const blob = await response.blob();

  return URL.createObjectURL(blob);
};

const generateDataSource = async () => {
  return await Promise.all(
    props.imagesData.map(async (url) => {
      const dataURL = await createBlobURL(url);
      const { width, height } = await getImageDimensions(dataURL);
      
      return { src: dataURL, largeURL: dataURL, height, width };
    })
  );
}

onMounted(async () => {
    if (!lightbox.value) {
        const appendElement = containerPhotoswiper.value;

        lightbox.value = new PhotoSwipeLightbox({
            // to apply styles just to this instance of PhotoSwipe
            mainClass: 'pswp--custom-bg',
            pswpModule: () => import('photoswipe'),
            dataSource: await generateDataSource(),
            secondaryZoomLevel: 2,
            maxZoomLevel: 3,
            zoom: true,
            appendToEl: appendElement,
        });

        lightbox.value.init();
    }
});

onUnmounted(() => {
    if (lightbox.value) {
        lightbox.value.destroy();
        lightbox.value = null;
    }
});
</script>

<template>
    <div class="gallery">
        <template v-for="(image, index) in $props.imagesData" :key="index">
            <img class="w-100 mb-2" :src="image" alt="img" @click="openGalleryIndex(index)" />
        </template>

        <div ref="containerPhotoswiper" />
    </div>
</template>
<style>
@import 'photoswipe/style.css';

.gallery {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
}

</style>