import app from "./App.vue";
import { pluginsWrapper } from "./plugins";
import { createWebComponent } from "vue-web-component-wrapper";
import {
  defineCustomElement as VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
} from "vue";

import customStyles from "./assets/style.css?inline";

createWebComponent({
  rootComponent: app,
  elementName: "primo-chat",
  plugins: pluginsWrapper,
  cssFrameworkStyles: customStyles,
  VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
  disableShadowDOM: false,
  replaceRootWithHostInCssFramework: true,
});