import { default as axios } from "../axiosInstance";

// Define the type for the response structure
interface ShowResponse<T> {
  data: T;
}

// Define the structure of the result that the function will return
interface ShowResult<T> {
  message: T;
}

/**
 * Function to fetch a specific message by ID and action.
 * @param id - The ID of the message to fetch.
 * @returns A promise resolving to an object containing the message data.
 */
export async function show<T = any>(
  id: string,
): Promise<ShowResult<T>> {
  let message: T;

  try {
    const response: ShowResponse<T> = await axios.get(`/messages/${id}`);
    message = response.data;
  } catch (error: any) {
    // If more specific error handling is needed, adjust as necessary.
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the full error if the expected structure is not found.
  }

  return { message };
}
