<script>
import { inject, computed } from "vue";
import { useMainStore } from "@/pinia/main.store";

export default {
  name: "TabNavigation",
  emits: ["selectChatType"],
  setup() {
    const { user } = useMainStore(); 
    const mobileLayout = inject("mobileLayout");
    const filterState = inject("filterState");
    const totalUnreadMessages = computed(() => user.totalUnreadMessages);

    return { mobileLayout, filterState, totalUnreadMessages };
  },
};
</script>
<template>
<ul class="d-flex justify-content-center align-items-center flex-wrap m-0 p-0 mb-2">
  <transition name="slide-down">
    <FilterDrawer
      @select-chat-type="(chatType, chatSubType, value) => $emit('selectChatType', chatType, chatSubType, value)"
      v-if="filterState.chatType === 'unseen'" class="w-100 px-3" />
  </transition>
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: filterState.chatType === 'unseen' }"
    :disabled="filterState.chatType === 'unseen'"
    @click="$emit('selectChatType', 'unseen', filterState.subChatType)"
  >
    <span
      v-if="filterState.chatType === 'unseen' && filterState.subChatType === 'by_unseen' && totalUnreadMessages > 0"
      class="badge rounded-pill bg-violet position-absolute translate-middle"
    >
      {{ totalUnreadMessages }}
    </span>
    <small class="m-auto">{{ $t('To read') }}</small>
  </button>
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: filterState.chatType === 'by_last_reading' }"
    :disabled="filterState.chatType === 'by_last_reading'"
    @click="$emit('selectChatType', 'by_last_reading')"
  >
    <small class="m-auto">{{ $t('Already read') }}</small>
  </button>
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: filterState.chatType === 'by_favorite' }"
    :disabled="filterState.chatType === 'by_favorite'"
    @click="$emit('selectChatType', 'by_favorite')"
  >
    <small class="m-auto">{{ $t('Favorites') }}</small>
  </button>
</ul>
</template>
<style lang="scss" scoped>
.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.7s ease;
  overflow: hidden;
}
.slide-down-enter-from, .slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-down-enter-to, .slide-down-leave-from {
  max-height: 100px;
  opacity: 1;
}

ul {
  button {
    border-radius: 0.5rem;
    padding: 6px 9px 6px 9px;
    font-size: 14px;

    &:hover{
      border-color: #b5a3ca;
    }
    &.active {
      background-color: #f3e9ff;
      border-color: #b5a3ca;
    }
  }
}

.badge {
  left: 100%;
}
</style>
