import { default as axios } from "../axiosInstance";

// Define the structure of the channel based on expected data.
interface Channel {
  // Example fields; replace with actual fields as necessary.
  id: string;
  name: string;
  data: {
    users?: []
  }
  // Add other fields as required.
}

// Define the structure of the response from the Axios request.
interface ShowResponse {
  data: Channel;
}

/**
 * Function to fetch details of a channel based on its ID and an action.
 * @param id - The ID of the channel to fetch.
 * @param action - The action to perform (e.g., filter type).
 * @returns A promise resolving to an object containing the channel data.
 */
export async function show(
  id: string,
  action: string,
): Promise<{ channel: Channel }> {
  let channel: Channel | {} = {};

  try {
    const response: ShowResponse = await axios.get(`/channels/${id}`, {
      params: {
        action: action,
      },
    });
    channel = response.data;
  } catch (error: any) {
    // Using 'any' for the error type. Adjust it based on actual error structure if available.
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the entire error if the expected structure is not found.
  }

  return { channel: channel as Channel }; // Ensure that the return type matches the expected Channel interface.
}
