<script>
import {
  orderBy as _orderBy,
  sortBy as _sortBy,
  map as _map,
  head as _head,
  filter as _filter,
  includes as _includes,
  sumBy as _sumBy,
  get as _get,
  find as _find,
  set as _set,
  maxBy as _maxBy,
  debounce as _debounce
} from "lodash";

import { edit as editChannel } from "@/use/repositories/chat/channel/edit";
import { index as fetchChannels } from "@/use/repositories/chat/channel/index";
import { show as showChannel } from "@/use/repositories/chat/channel/show";
import { show as showMessage } from "@/use/repositories/chat/message/show";
import { update as updateMessage } from "@/use/repositories/chat/message/update";

import { useMainStore } from "@/pinia/main.store";
import { onMounted, ref, provide, reactive, computed, watch } from "vue";
import { useLayoutObserver } from "../composables/useLayoutObserver";
import { useVisibilityController } from '../composables/useVisibilityController';

export default {
  name: "Widget",
  emits: ["close"],
  props: {
    expanded: { type: Boolean }
  },
  setup(props) {
    const store = useMainStore();
    const searchTerm = ref("");
    const chatType = ref();
    const subChatType = ref();
    const showChatWidget = ref(false);
    const containerElement = ref(null);
    const { mobileLayout, onlyFullScreen } = useLayoutObserver(containerElement);
    const isFullScreen = ref(false);
    const channels = ref([]);
    const loading = ref(false);
    const pusherEvent = inject("pusherEvent");

    useVisibilityController(mobileLayout);
  
    const chatTypeActions = {
      all_chat: {
        all: "by_clinic_unseen_new",
        personal: "by_clinic_my_unseen_new",
      },
      auth_user_chat: "by_clinic_my_unseen_new",
      favorite_chat: "by_favorite_new",
      by_last_reading: "by_last_reading",
    };

    const activeChannel = reactive({
      prescription_id: null,
      chat_channel: {},
      customer: {},
      name: null,
      is_favorite: null,
      description: null,
    });

    const handlePusherEvent = async (event) => {
      switch (event.eventName) {
        case "pusher:subscription_succeeded":
          break;
        case "messaging":
            await handleIncomingMessage(event.data);
          break;
        default:
          console.log('Unknown event');
      }
    };

    const handleIncomingMessage = async (messageEvent) => {

      if (activeChannel.value === messageEvent.chat_channel_id) return;

      if (chatType.value !== 'all_chat' && subChatType.value !== 'all' ) return

      const existingChannel = _find(channels.value, channel => channel.id === messageEvent.chat_channel_id);

      if (existingChannel) {
        await addMessageToExistingChannel(existingChannel, messageEvent);
      } else {
        await addNewChannelWithMessages(messageEvent.chat_channel_id);
      }

      if (messageEvent.user_id === store.user.id) {
        markAsRead(messageEvent.chat_channel_id, messageEvent.id);
      }
    }

    const handleChannelFetching = async (action, page = 1, value = null) => {
      resetActiveChannel();
      if (page === 1) channels.value = [];
      setTimeout(() => (loading.value = true));

      try {
        const response = await fetchChannels(action, page, value);

        if (response.data.length === 0 || response.data.length < 10) {
          hasMore.value = false;
        }

        if (page > 1) {          
          const newProcessedChannels = sortAndMapChannelMessages(response.data);
          channels.value = [...channels.value, ...newProcessedChannels];
        } else {
          channels.value = sortAndMapChannelMessages(response.data);
        }

        return response.data;
      } catch (error) {
        if (error.name === "AbortError") console.log("Aborted");
        else console.error("Error", error);
      } finally {
        loading.value = false;
      }
    };

    const addMessageToExistingChannel = async (channel, messageEvent) => {
      try {
        const { message: { data } } = await showMessage(messageEvent.id);
        channel.messages.push(data);
      } catch (error) {
        console.error('Error', error);
      }
    };

    const addNewChannelWithMessages = async (channelId) => {
      try {
        const { channel: { data } } = await showChannel(channelId, "with_new_messages");
        const processedChannel = _head(sortAndMapChannelMessages([data]));
        channels.value.push(processedChannel);
      } catch (error) {
        console.error('Error', error);
      }
    };

    const searchChannels = _debounce(async (value) => {
      if (value.length >= 3) {
        unsetChatType();
        resetPagination();
        await handleChannelFetching('by_search', currentPage.value, value);
      }
    }, 300);

    const setChatType = async (value = "all_chat", subFilter = "all") => {
      chatType.value = value;
      subChatType.value = subFilter;
      const action = getAction();
      resetPagination();

      const data = await handleChannelFetching(action, currentPage.value);

      if (chatType.value === "auth_user_chat" && data) {
        store.setUserPersonalMessagesUnread(data);
      }
    };

    const hasMore = ref(true);
    const currentPage = ref(1);
    
    const getAction = () => {
      switch (chatType.value) {
        case 'all_chat':
          return chatTypeActions.all_chat[subChatType.value];
        case null:
          return "by_search";
        default:
          return chatTypeActions[chatType.value]
      }
    };

    const loadMore = async () => {
      if (hasMore.value && !loading.value) {
        currentPage.value += 1;
        const action = getAction();
        const term = (searchTerm.value = searchTerm.value.trim()) || null;

        await handleChannelFetching(action, currentPage.value, term);
      }
    };

    const resetPagination = () => {
      currentPage.value = 1;
      hasMore.value = true;
    };

    const unsetChatType = () => chatType.value = null;

    const sortAndMapChannelMessages = (channels) => {
      // Create default message object
      channels.forEach((channel) => {
        if (! channel.messages || ! channel.messages.length) {
          channel.messages = [{ ...channel.last_message, isRead: true }];
        } else {
          channel.messages.forEach(message => message.isRead = ! message.created_at);
        }
      });

      const sortedChannels = _sortBy(
        channels,
        channel => channel.messages[channel.messages.length - 1]?.created_at || 0, // Fallback value
        ["asc"]
      ).reverse();

      // Manage reading badge display
      return _map(sortedChannels, (channel) => {
        return {
          ...channel,
          has_been_read: _head(channel.messages)?.created_at ? false : true,
        };
      });
    }

    const openConversation = (channel) => {
      setActiveChannel(channel);

      // Conversation already read. Open conversation
      if (channel.messages.every(message => message.isRead)) return;

      markAsRead(channel.id);
    };

    const setActiveChannel = async (channel) => {
      await showChannel(channel.id, "user_extra").then(
        response => (activeChannel.is_favorite = response.channel?.meta?.is_favorite),
      );

      activeChannel.prescription_id = channel.chat_channelable.id;
      activeChannel.chat_channel = { id: channel.id };
      activeChannel.customer = channel.chat_channelable.customer;
      activeChannel.name = channel.name;
      activeChannel.clinic = channel.chat_channelable.clinic.name;
      activeChannel.description = channel.description
    };

    const resetActiveChannel = () => {
      activeChannel.prescription_id = null;
      activeChannel.chat_channel = {};
      activeChannel.customer = {};
      activeChannel.name = null;
      activeChannel.clinic = null;
      activeChannel.is_favorite = null;
    };

    const markAsRead = async (channelId, messageId = null) => {
      const channel = _find(channels.value, { id: channelId });

      if (! channel) return;

      const messages = messageId ? [_find(channel.messages, { id: messageId })] : channel.messages;

      if (! messages.some(message => ! message.isRead)) return;

      const messagesToUpdate = messages.filter(message => message.user_id !== store.user.id);

      for (const message of messages) message.isRead = true;

      if (messagesToUpdate.length) {
        await updateMessage({ action: "update_readings", message_ids: messagesToUpdate.map(item => item.id) });
      }

      store.decreasePersonalMessagesCount(messages.length);
    };

    const toFavorite = (channel) => {
      const value = activeChannel.is_favorite === null ? true : !activeChannel.is_favorite;

      editChannel(channel.id, "sync_favorite", value)
        .then(() => (activeChannel.is_favorite = value));
    };

    const orderedChannels = computed(() => {

      return  _orderBy(channels.value, (channel) => {
        const latestMessage = _maxBy(channel.messages, 'created_at');
        return latestMessage ? latestMessage.created_at : '';
      }, ['desc']);
    });

    watch(
      () => pusherEvent,
      newEvent => handlePusherEvent(newEvent),
      { deep: true },
    );

    watch(
      () => searchTerm.value,
      (newValue) => {
        const searchTerm = newValue.trim();

        if (searchTerm === "") return setChatType()
        
        return searchChannels(searchTerm);
      }
    );

    watch(() => store.expanded, value => isFullScreen.value = value, { immediate: true });

    watch(() => onlyFullScreen.value, value => store.expanded = value, { immediate: true });

    onMounted(() => {
      setChatType();
      store.expanded = props.expanded;
    });

    provide("activeChannel", activeChannel);
    provide("chatType", chatType);
    provide("subChatType", subChatType);
    provide("loadMore", loadMore);
    provide("markAsRead", markAsRead);

    return {
      mobileLayout,
      containerElement,
      isFullScreen,
      channels,
      orderedChannels,
      searchTerm,
      chatType,
      loading,
      openConversation,
      setChatType,
      showChatWidget,
      toFavorite,
    };
  },
};
</script>
<template>
  <CustomModal :fullscreen="isFullScreen">
    <div class="d-flex w-100 h-100" ref="containerElement" >
      <ConversationsControl
        :channels="orderedChannels"
        :search="searchTerm"
        :spinner="loading"
        @select-chat-type="setChatType"
        @select-conversation="openConversation"
        @update:search="searchTerm = $event" />
      <Conversation @to-favorite="toFavorite" />
    </div>
  </CustomModal>
</template>
<style lang="scss" scoped>
.dot {
  top: 5px;
  right: 0;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}
</style>
