<script lang="ts">
import { defineComponent, ref, onMounted, inject } from "vue";
import { show as showChannel } from "../use/repositories/chat/channel/show";
import _groupBy from "lodash/groupBy";
import _orderBy from "lodash/orderBy";
import _mapValues from "lodash/mapValues";

interface User {
  id: string;
  first_name: string;
  last_name: string;
  extra?: {
    role: { name: string };
  };
}

interface Channel {
  id: string;
  chat_channel: { id: string }
}

export default defineComponent({
  name: "ConversationDetailsTabs",
  setup() {
    const activeTab = ref<"media" | "media">("media");
    
    const channel = inject<Channel>("activeChannel");

    const mobileLayout = inject<boolean>("mobileLayout");
    
    const users = ref<User[]>([]);

    const setActiveTab = (tab: "users" | "media") => activeTab.value = tab;

    onMounted(async () => {
      if (channel?.chat_channel.id) {
        try {
          const { channel: { data } } = await showChannel(channel.chat_channel.id, 'with_users');
          users.value = _mapValues(
            _groupBy(data.users, user => (user.extra?.role?.role_category?.slug === 'laboratory') && 'laboratory' || 'clinic'),
            group => _orderBy(group, ['first_name'], ['asc'])
          );
        } catch (error) {
          console.error(error);
        }
      }
    });

    return { activeTab, setActiveTab, users, channel, mobileLayout };
  },
});
</script>
<template>
  <div class="body-tabs">
    <ul class="nav nav-pills nav-fil justify-content-center p-4">
      <li class="nav-item">
        <button type="button" class="nav-link" :class="{ active: activeTab === 'media' }" @click="setActiveTab('media')">
          Media
        </button>
      </li>
      <li class="nav-item">
        <button type="button" class="nav-link" :class="{ active: activeTab === 'users' }" @click="setActiveTab('users')">
          Utenti
        </button>
      </li>
    </ul>
    <transition name="fade" mode="out-in">
      <div :key="activeTab" class="container tab-container">
        <div v-if="activeTab === 'media'" class="content-tab">
          <h2 class="text-dark-violet p-2">Media della conversazione</h2>
          <Gallery :channel="channel" class="image-gallery" />
        </div>
        <div v-if="activeTab === 'users'" class="content-tab">
          <div v-for="(group, key, index) in users" :key="index">
            <h3 class="text-dark-violet p-2">{{ $t(key.replace(/^./, key[0].toUpperCase())) }}</h3>
            <ul class="list-group">
              <li class="list-group-item d-flex align-items-center" v-for="user in group" :key="user.id">
                <template v-if="user.first_name && user.last_name">
                  <img src="@/assets/icons/customer.svg" width="40" alt="customer" class="border border-1 rounded-circle p-2"/>
                  <span class="mx-2">{{ user.first_name }} {{ user.last_name }}</span>
                  <span class="badge badge-pill bg-dark-violet mx-2"> {{ user.extra?.role?.display_name }}</span>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.nav {
  height: 5rem;
}
.content-tab {
  overflow: auto;
  height: calc(100%);
}
.tab-container {
height: calc(100% );
}

.body-tabs {
  height: calc(100% - 6rem);
  padding-bottom: 5rem;
  background-color: #f3eef8c9;
  background-image: url("@/assets/icons/logo.svg");
  background-color: rgba(243, 238, 248, 0.7882352941);
  background-position: center;
  background-blend-mode: lighten;
  background-size: 25rem;
  background-repeat: no-repeat;
}

.body-tabs.mobile {
  height: calc(100% - 8.5rem);
}

.nav-pills .nav-link.active {
  background-color: #A58ABA;
  border-color: #A58ABA;
  color: #ffff;
}

.nav-pills .nav-link {
  cursor: pointer;
  color: #A58ABA;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
