<script lang="ts">
import { defineComponent } from "vue";
import { useMainStore } from "@/pinia/main.store";

interface MainStore {
  showApp: boolean;
  expanded: boolean;
}

export default defineComponent({
  name: "WindowActions",
  setup() {
    const mainStore = useMainStore() as MainStore;
    const onlyFullScreen = inject("onlyFullScreen");

    const closeApp = (): void => {
      mainStore.showApp = false;
      mainStore.expanded = false;
    }

    const toggleFullScreen = (): boolean => mainStore.expanded = !mainStore.expanded;

    return { closeApp, toggleFullScreen, mainStore, onlyFullScreen };
  },
});
</script>

<template>
  <div class="window-controls">
    <template v-if="! onlyFullScreen">
      <button @click="toggleFullScreen" class="btn p-0 ms-2">
        <img v-if="! mainStore.expanded" src="@/assets/icons/expand.svg" alt="fullscreen" width="20" />
        <img v-else src="@/assets/icons/reduce.svg" alt="fullscreen" width="25" />
      </button>
    </template>
    <button @click="closeApp" class="btn">
      <img src="@/assets/icons/close.svg" alt="close" width="45" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.window-controls {
  display: flex;
  align-items: baseline;

  .btn {
    padding: 0;
    margin: 0;
  }
  .btn > img {
    filter: invert(0.75);
  }
}
</style>
