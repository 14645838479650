<script>
import { watch, ref, inject } from "vue";
import { Message, Spinner } from "@/components";
import { useIntersectionObserver } from "../composables/useIntersectionObserver";

export default {
  name: "Body",
  components: { Message, Spinner },
  props: {
    chat: {
      type: Array,
      default: () => [],
    },
    spinner: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["getHistory"],
  setup(props, { emit }) {
    const conversation = ref(null);
    const activeChannel = inject("activeChannel");
    const markAsRead = inject("markAsRead");

    const { target: topObserver } = useIntersectionObserver(() => emit("getHistory"));

    const { target: bottomObserver } = useIntersectionObserver(() => handleScroll());

    const handleScroll = () => {
      markAsRead(activeChannel?.chat_channel?.id)
    }

    const scrollToBottom = (millisecond = 1000) => {
      setTimeout(() => {
        const el = conversation.value;
        if (el) el.scroll({ top: el.scrollHeight, behavior: "smooth" });
      }, millisecond);
    };

    watch(() => props.chat, () => scrollToBottom());

    return { 
      conversation,
      activeChannel,
      topObserver,
      bottomObserver,
      scrollToBottom
    };
  },
};
</script>

<template>
  <div ref="conversation" class="conversation overflow-auto flex-grow-1 position-relative">
    <div ref="topObserver"></div>
    <WindowActions v-if="! activeChannel.prescription_id" class="btn close-modal m-2 position-absolute end-0" />
    <div class="my-3">
      <div class="d-flex flex-column w-100">
        <template v-if="($props.chat.length && activeChannel.prescription_id)">
          <template v-for="(messageGroup, i) in $props.chat" :key="i">
            <div class="d-flex justify-content-center align-items-center">
              <div class="d-flex justify-content-center p-2 shadow rounded">
                {{ messageGroup.date }}
              </div>
            </div>
            <Message
              v-for="(message, index) in messageGroup.messages"
              :key="index"
              :message="message"
            />
          </template>
          <template v-if="$props.spinner">
            <Spinner class="m-auto" />
          </template>
        </template>
        <template v-else>
          <h5 class="m-auto text-muted mt-5">
            Seleziona una chat per continuare
          </h5>
        </template>
      </div>
    </div>
    <div ref="bottomObserver"></div>
  </div>
</template>

<style scoped lang="scss">
.conversation {
  height: calc(100% - 30rem);
  background-color: #f3eef8c9;
  background-image: url("@/assets/icons/logo.svg");
  background-color: rgba(243, 238, 248, 0.7882352941);
  background-position: center;
  background-blend-mode: lighten;
  background-size: 25rem;
  background-repeat: no-repeat;
}

.conversation__message__badge__new__message {
  bottom: 60px;
  padding: 5px;
}

.conversation__message__badge__icon__caretdown {
  &:hover {
    cursor: pointer;
  }
}

.end {
  align-items: end;
}

.start {
  align-items: start;
}

button {
  border: 0;
  padding: 0;
  background: unset;
}

.media:hover {
  cursor: pointer;
}

.info {
  background-color: #138496 !important;
  color: white !important;
}

.warning {
  background-color: #fade2d !important;
  color: white !important;
}

.alert {
  background-color: rgb(233, 123, 134) !important;
  color: white !important;
}

.spinner {
  text-align: center;
}
</style>
