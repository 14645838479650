import { ref, onMounted, onBeforeUnmount } from "vue";

interface Options {
  root?: Element | null;
  threshold?: number;
}

/**
 * 
 * @param { () => void } callback - The function to call when the observed element becomes visible.
 * @param { Options } options - Optional configuration object for the IntersectionObserver.

 * @returns { { target: Ref<Element | null> } } - Returns ref to the element being observed.
 */

export function useIntersectionObserver(
  callback: () => void,
  options: Options = { root: null, threshold: 0.1 }
) {
  const target = ref<Element | null>(null);

  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      console.log('intersect');
      callback();
    }
  }, options);

  onMounted(() => {
    if (target.value) {
      observer.observe(target.value);
    }
  });

  onBeforeUnmount(() => {
    if (target.value) {
      observer.unobserve(target.value);
    }
  });

  return { target };
}
