import axios, {
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosHeaders,
} from "axios";
import getAuthToken from "./getAuthToken";

// Create axios instance with the base URL
const primoChatApi = axios.create({
  baseURL: import.meta.env.VITE_API_CHAT_BASE_URL,
});

let currentAbortController: AbortController | null = null;

// Add a request interceptor to include the authorization token
primoChatApi.interceptors.request.use(
  (
    config: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
    const token = getAuthToken();

    if (token) {
      // Use AxiosHeaders to handle headers correctly
      if (config.headers instanceof AxiosHeaders) {
        config.headers.set("Authorization", `Bearer ${token}`);
      } else {
        config.headers = new AxiosHeaders({
          Authorization: `Bearer ${token}`,
        });
      }
    }

    if (currentAbortController) currentAbortController.abort();

    currentAbortController = new AbortController();
    config.signal = currentAbortController.signal;

    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    // Return a rejected promise with the error
    return Promise.reject(error);
  },
);

// Add a request interceptor
primoChatApi.interceptors.response.use(
  (response) => {
    currentAbortController = null;
    return response;
  },
  (error: AxiosError) => {
    if (axios.isCancel(error)) console.log("Request canceled", error.message);

    return Promise.reject(error);
  },
);

export default primoChatApi;
