import { default as axios } from "../axiosInstance";

// Structure of the response
interface IndexResponse<T> { data: T }

// Structure of value return 
interface IndexResult<T> { data: T }

// Parameters type
interface IndexParams { [key: string]: any }

/**
 * @param params - The query parameters to pass with the request.
 * @returns A promise resolving to an object containing the fetched data.
 */
export async function index<T = any>(
  params: IndexParams,
): Promise<IndexResult<T>> {
  let data: T;

  try {
    const response: IndexResponse<T> = await axios.get("/clinics", { params });
    data = response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error;
  }

  return { data };
}
