<script>
import { ref, onMounted, watchEffect } from "vue";
import { assignUrl } from "@/use/repositories/chat/media/assignUrl";
import { store as storeMessageChat } from "@/use/repositories/chat/message/store";

export default {
  name: "MultiUploadWrapper",
  emits: ["media-ready", "uploadCompleted", "update:mediaCounter"],
  props: {
    channelId: { type: Number, required: true },
    textMessage: { type: String },
    mediaCounter: { type: Number }
  },
  setup(props, { expose, emit }) {
    const counter = ref(0);
    const formData = ref(new FormData());
    const multiUpload = ref(null);

    const upload = () => {
      if (multiUpload.value) multiUpload.value.processQueue();
    }

    const removeAllFiles = () => {
      if (multiUpload.value) multiUpload.value.removeAllFiles();
    };

    const uploadCompleted = () => {
      counter.value = 0;
      emit('uploadCompleted');
    }

    watch(
      () => counter.value, 
      newValue => emit('update:mediaCounter', newValue)
    );

    watchEffect(() => formData.value.set('body', props.textMessage));

    onMounted(() => {
      formData.value.set('action', 'with_media');
      formData.value.set('type_slug', 'default');
      formData.value.set('chat_channel_id', props.channelId);
      formData.value.set('body', props.textMessage);
    });

    expose({ removeAllFiles, upload });

    return { props, counter, storeMessageChat, assignUrl, formData, multiUpload, uploadCompleted };
  },
};
</script>

<template>
  <MultiUpload
    ref="multiUpload"
    :label="$t('Drag file here or click to select')"
    :upload="false"
    :multi-upload="true"
    :max-files="10"
    :assign-url-s3="assignUrl"
    :store-url="storeMessageChat"
    :form-data="formData"
    @increment="counter++"
    @decrease="counter--"
    @upload-completed="uploadCompleted"
  >
  <img src="@/assets/icons/upload.svg" alt="upload" />
  </MultiUpload>
</template>