// Define the type for the file parameter
interface File {
  name: string;
}

/**
 * Formats a filename by appending the current timestamp before the file extension.
 *
 * @param file - The file object containing the name property.
 * @returns The formatted filename with a timestamp.
 */
export function formatFilename(file: File): string {
  // Get filename and extension using the helper function
  const [fileName, extension] = parseFileName(file.name);

  // Return the formatted filename with timestamp
  return `${fileName}_${new Date().getTime()}.${extension}`;
}

/**
 * Parses the file name into the name and extension.
 *
 * @param filename - The filename string to be parsed.
 * @returns An array where the first element is the file name and the second is the file extension.
 */
const parseFileName = (filename: string): [string, string] => {
  // Extract the file part after the last slash (if any)
  const file = filename.split("/").pop() || filename;

  // Extract the file name and extension
  const lastDotIndex = file.lastIndexOf(".");
  const name = file.substr(0, lastDotIndex);
  const extension = file.substr(lastDotIndex + 1);

  // Return the name and extension as a tuple
  return [name, extension];
};
