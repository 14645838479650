<script>
import ImageGallery from "./ImageGallery.vue";
export default {
  name: "Message",
  components: {
    ImageGallery,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
};
</script>
<template>
  <div class="conversation__message shadow d-flex flex-column" 
    :class="{
      'align-self-end': $props.message.is_sender,
      'text-light': $props.message.channel_message_type !== 'default',
    }"
    :style="{ backgroundColor: $props.message.channel_message_color }">
    <!-- Message -->
    <div class="conversation__message__user fw-bold mb-2">
      {{ $props.message.first_name }} {{ $props.message.last_name }}
      <span class="badge badge-pill bg-dark-violet ms-4">{{ $props.message.role }}</span>
    </div>
    <small>{{ $props.message.text }}</small>
    <template v-if="$props.message.attachments.length">
      <ImageGallery class="w-100" :galleryID="$props.message.chat_channel_id" :imagesData="$props.message.attachments" />
    </template>
    <small class="d-flex justify-content-end align-items-center">
      {{ $props.message.time }}
      <img v-if="$props.message.is_placeholder" class="ms-1" width="20" src="@/assets/icons/check.svg" alt="clock">
      <img v-else-if="$props.message.is_sender" width="25" src="@/assets/icons/double-checks.svg" alt="double-checks">
    </small>
  </div>
</template>
<style scoped lang="scss">

.conversation__message {
  border-radius: 0.5rem;
  margin: 1rem 1.5rem;
  padding: 0.5rem;
  width: fit-content;
  min-width: 15rem;
  max-width: 30rem;
  white-space: pre-line;
}

.info {
  background-color: #138496 !important;
  color: white !important;
}

.warning {
  background-color: #dd7230 !important;
  color: white !important;
}

.alert {
  background-color: rgb(233, 123, 134) !important;
  color: white !important;
}

.conversation__message__user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
