<script>
import { ref, onMounted, defineComponent, onUnmounted } from 'vue';
import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;
import { formatFilename } from '@/use/utilities/media/formatFilename';

export default defineComponent({
  name: 'MultiUpload',
  props: {
    label: {
      type: String,
      default: 'Drag file here or click to select'
    },
    multiUpload: { type: Boolean, default: false },
    maxFiles: { type: Number, default: 1 },
    maxFilesize: { type: Number, default: 10 },
    assignUrlS3: { type: Function, required: true },
    storeUrl: { type: Function, required: false },
    formData: { type: Object, required: false },
  },
  setup(props, { expose, emit }) {
    const dropzoneForm = ref(null);
    const dropzoneMessage = ref(null);
    const mediaNames = ref([]);
    const file = ref(null);
    let dropzone = null;

    const processQueue = () => { 
      if (dropzone && file.value) dropzone.processQueue() 
    };

    const removeAllFiles = () => {
      if (dropzone) dropzone.removeAllFiles(true);

      mediaNames.value = [];
    };

    onMounted(() => {
      const options = {
        url: '/',
        method: 'POST',
        acceptedFiles: 'image/*',
        maxFiles: props.maxFiles,
        maxFilesize: props.maxFilesize,
        dictDefaultMessage: dropzoneMessage.value.innerHTML,
        autoProcessQueue: false,
        addRemoveLinks: false,
        parallelUploads: props.maxFiles,
        uploadMultiple: false,
        headers: { Accept: '*/*' },
        defaultHeaders: true,
        accept(fileObj, done) {
          file.value = fileObj;
          props.assignUrlS3(fileObj.upload.filename).then((response) => {
            fileObj.s3 = {
              url: response.assignUrl.attributes.action,
              inputs: response.assignUrl.inputs,
            };
            mediaNames.value.push(fileObj.upload.filename);
            done();
          });
        },
        sending(fileObj, xhr, formData) {
          Object.entries(fileObj.s3.inputs).forEach(([key, value]) => {
            formData.append(key, value);
          });
        },
        success(fileObj) {
          if (fileObj.previewElement) fileObj.previewElement.classList.add('dz-success');
        },
        renameFile: formatFilename,
        queuecomplete() {
          mediaNames.value.forEach((mediaName) => props.formData.append('file_names[]', mediaName));
          props.storeUrl(props.formData, 'with_media').then(() => {
            dropzone.removeAllFiles(true);
            mediaNames.value = []; 
            emit('upload-completed');
            emit('restore');
          });
        },
        error(fileObj, message) {
          if (fileObj.previewElement) {
            fileObj.previewElement.classList.add('dz-error');
            for (let node of fileObj.previewElement.querySelectorAll('[data-dz-errormessage]')) {
              node.textContent = typeof message === 'string' ? message : message.error;
            }
          }
        },
      };
      // Generate Dropzone instance.
      dropzone = new Dropzone(dropzoneForm.value, options);

      dropzone.on('addedfile', (fileObj) => {
        fileObj.previewElement.addEventListener('click', () => dropzone.removeFile(fileObj));
        emit('increment');
      });

      dropzone.on('removedfile', (fileObj) => {
        const index = mediaNames.value.findIndex((el) => el === fileObj.upload.filename);
        if (index !== -1) mediaNames.value.splice(index, 1);
        emit('decrease');
      });

      dropzone.on('processing', (fileObj) => dropzone.options.url = fileObj.s3.url);

      dropzone.on('maxfilesexceeded', (fileObj) => dropzone.removeFile(fileObj));
    });

    onUnmounted(() => dropzone.destroy());

    expose({ removeAllFiles, processQueue });

    return { dropzoneForm, dropzoneMessage, mediaNames };
  },
});
</script>

<template>
  <form ref="dropzoneForm" class="dropzone bt-b-radius mb-3 text-muted text-center">
    <slot></slot>
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div ref="dropzoneMessage" style="display: none;">
      <div class="dropzone-title">{{ label }}</div>
    </div>
  </form>
</template>

<style>
@import 'dropzone/dist/dropzone.css'; 
.dropzone {
  border: 2px dashed rgba(0, 0, 0, 0.2) !important;
}
</style>
