<script setup lang="ts">
import { onMounted, onUnmounted, Ref, ref, inject, defineProps } from 'vue';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import dayjs from "dayjs";

interface Props {
  imagesData: object[],
  viewMode?: 'single' | 'gallery'
}

interface Image {
  src: string;
  largeURL: string;
  width: number;
  height: number;
}

const props = defineProps<Props>();

const onLoading = ref<boolean>(false);

const mobileLayout =  inject<boolean>("mobileLayout");

const containerPhotoswiper = ref<HTMLElement | null>(null);

const images = ref<Image[] | null>(null);

const lightbox: Ref<PhotoSwipeLightbox | null> = ref(null);

const openGalleryIndex = async (index: number) => {
    lightbox.value?.loadAndOpen(index);
};

const generateDataSource = async (): Promise<Image[]> => {
  return await Promise.all(
    props.imagesData.map(async (imageData) => {
      const { 
        temporary_url,
        body,
        created_at,
        user,
        custom_properties: { width, height } = {} // Default `custom_properties` to an empty object
      } = imageData;

      return { 
        src: temporary_url || '',
        largeURL: temporary_url || '',
        width,
        height,
        body,
        created_at: dayjs(created_at).format("DD/MM/YYYY HH:mm"),
        user,
      };
    })
  );
};


const loadImages = async (): Promise<void> => {
  try {
    onLoading.value = true;
    images.value = await generateDataSource();
  } catch (error) {
    console.error('Error loading images:', error);
  } finally {
    onLoading.value = false;
  }
};

const initializeLightbox = (): void => {
  if (lightbox.value) return;

  const appendElement = containerPhotoswiper.value;

  if (appendElement) {
    lightbox.value = new PhotoSwipeLightbox({
      mainClass: 'pswp--custom-bg',
      pswpModule: () => import('photoswipe'),
      dataSource: images.value,
      secondaryZoomLevel: 2,
      maxZoomLevel: 3,
      zoom: true,
      appendToEl: appendElement,
      gallery: '#gallery--with-custom-caption',
      children: '.pswp-gallery__item',
    });

    lightbox.value.on('uiRegister', registerCustomCaption);
    lightbox.value.init();
  }
};

const registerCustomCaption = (): void => {
  if (! lightbox.value?.pswp?.ui) return;

  lightbox.value.pswp.ui.registerElement({
    name: 'custom-caption',
    order: 9,
    isButton: false,
    appendTo: 'root',
    html: 'Caption text',
    onInit: (el: HTMLElement, pswp: any): void => {
      pswp.on('change', () => updateCaption(el));
    },
  });
};

const updateCaption = (el: HTMLElement): void => {
  const currSlideElement = lightbox.value?.pswp?.currSlide.data.element;
  let captionHTML = `${lightbox.value?.pswp?.currSlide?.data?.created_at} - ${lightbox.value?.pswp?.currSlide?.data?.user?.first_name} ${lightbox.value?.pswp?.currSlide?.data?.user?.last_name} ${lightbox.value?.pswp?.currSlide?.data?.body.trim().length ? ':<br>' : '' } 
    ${lightbox.value?.pswp?.currSlide?.data?.body}`;

  if (currSlideElement) {
    const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
    if (hiddenCaption) {
      captionHTML = hiddenCaption.innerHTML;
    } else {
      const img = currSlideElement.querySelector('img');
      captionHTML = img?.getAttribute('alt') || '';
    }
  }

  el.innerHTML = captionHTML || '';
};

onMounted(async () => {
    await loadImages();
    initializeLightbox();
  });

onUnmounted(() => {
    if (lightbox.value) {
        lightbox.value.destroy();
        lightbox.value = null;
    }
});
</script>

<template>
  <div class="gallery-container">
    <Spinner v-if="onLoading" class="m-5"/>
    <div v-else  :class="[viewMode, { 'mobile': mobileLayout }]">
      <template v-for="(image, index) in images" :key="index">
        <div class="image-wrapper">
          <span v-if="viewMode === 'gallery'" class="badge">{{ image.created_at }} <br> {{ image.user.first_name }} {{ image.user.last_name }}</span>
          <img class="thumb bg-dark-violet" alt="img" :src="image.src" @click="openGalleryIndex(index)" />
        </div>
      </template>
    </div>
    <div ref="containerPhotoswiper" />
  </div>
</template>

<style lang="scss">
@import 'photoswipe/style.css';

.gallery-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  align-items: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  width: 100%;

  .image-wrapper {
    position: relative;
    &:hover .badge {
      transition: 0.3s;
      opacity: 1;
    }
  }

  .badge {
    position: absolute;
    top: 5px;
    left: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 3px 5px;
    font-size: 0.75rem;
    border-radius: 3px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
  }

  .thumb {
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    object-fit: cover;
    border: 1px solid #725D82;
  }

  .thumb:hover {
    box-shadow: 0 4px 10px #725D82;
  }

  &.mobile {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.single {
  .image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .thumb {
    max-height: 20rem;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    margin: 0.5rem 0;
  }
}

.pswp__custom-caption {
  background: #725D82;
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}
</style>
