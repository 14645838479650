<script>
export default {
  name: "SearchBar",
  emits: ["update:search"],
  props: {
    placeholder: { type: String, default: "Cerca" },
    inputClass: { type: String, default: "form-control rounded-3" },
    value: { type: String, default: "" },
  },
  setup(props, { emit }) {

    const updateSearch = event => emit("update:search", event.target.value.toUpperCase());

    return { updateSearch };
  },
};
</script>

<template>
  <div class="input-with-icon">
    <img class="icon" src="@/assets/icons/lens.svg" width="15" alt="search"/>
    <input
      :class="inputClass"
      type="text"
      :placeholder="placeholder"
      aria-label="Search"
      :value="value"
      @input="updateSearch"
    />
  </div>
</template>

<style lang="scss" scoped>
.input-with-icon {
  position: relative;

  .form-control {
    padding-left: 2.5rem;
  }

  .icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
</style>

