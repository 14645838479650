<script>
import { index as countMessages } from "@/use/repositories/chat/message/index";
import { useMainStore } from "@/pinia/main.store"; // Adjust the path as necessary
import { useFilterProps } from '@/composables/useFilterProps';

import { head as _head, pick } from 'lodash';
import {
  ref,
  computed,
  getCurrentInstance,
  provide,
  reactive,
} from "vue";
import Widget from "./components/Widget.vue";

export default {
  name: "App",
  components: { Widget },
  props: {
    authToken: {
      type: String,
      required: true,
    },
    user: {
      type: [Object, String], // Accept both string (JSON) or object
      required: true,
    },
    background: { type: Boolean },
    expanded: { type: Boolean }
  },
  setup(props) {
    const authUser = reactive({});
    const store = useMainStore();
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext?.config?.globalProperties?.$pusher;
    const customEvent = reactive({ eventName: null, data: null });
    const message = ref("");

    const filterAuthUserProps = (user) => {
      // List of props we want to allow (whitelist)
      const allowedProps = [
        'id',
        'email',
        'first_name',
        'last_name',
      ];

      // Use the composable function to filter the data
      const filteredProps = useFilterProps(user, allowedProps);

      // Set auth user.
      Object.keys(filteredProps).forEach(index => authUser[index] = filteredProps[index]);
    };

    const counter = computed(() => store.user.totalUnreadMessages);

    const toggle = value => store.showApp = value;

    const subscribe = (channelName, message) => {
      console.log(`subscribing  from "${channelName}"...`, { $pusher: pusher });
      let channel = pusher.subscribe(channelName);
      console.log('channel', channel);

      // Bind to all events on the channel
      channel.bind_global(updateCustomEvent, message);
    };

    const updateCustomEvent = (eventName, data) => {
      if (eventName === "messaging") {

        if (mustIncreaseCounter(data)) {
          store.increasePersonalMessagesCount(1);
        }

        customEvent.eventName = eventName;
        customEvent.data = data;
      }
    };

    const mustIncreaseCounter = (message) => message.user_id !== authUser.id;

    const setUnreadMessagesCount = async () => {
      try {
        const { data: { data } } = await countMessages({ action: 'by_unseen_count' });

        if (data) {
          store.setUserPersonalMessagesUnread(_head(data).total);
        }
      }
      catch (error) {
        if (error.name === "AbortError") console.log("Aborted");
        else console.error("Error", error);
      }
    }

    const dispatchBehavior = (value) => {
      const event = new CustomEvent('chat-state-changed', { detail: { isOpen: value } });
      window.dispatchEvent(event);
    }

    const configureUserSession = () => {
      const user = typeof props.user === "string" ? JSON.parse(props.user) : props.user;

      // Filter auth user props.
      filterAuthUserProps(user);
  
      store.setUser({
        authToken: props.authToken,
        ...authUser,
      });

      subscribe(`presence-user.${user.id}`, message);

      setUnreadMessagesCount();
    }

    watch(
      () => store.showApp,
      (value) => dispatchBehavior(value)
    );

    watch(
      () => props.user,
      value => value && configureUserSession(),
      { deep: true, immediate: true },
    );

    provide("pusherEvent", customEvent);
    provide("user", authUser);

    return { counter, store, toggle };
  },
};
</script>

<template>
  <div class="prevent-style" :class="{'with-background': $props.background }">
    <div v-if="!store.showApp" @click="toggle(true)" class="widget-icon">
      <img class="chat cp m-1" src="@/assets/icons/chat.svg" width="45" alt="chat" />
      <span
        v-if="counter > 0"
        class="position-absolute translate-middle badge rounded-pill bg-violet"
      >
        {{ counter }}
        <span class="visually-hidden">unread messages</span>
      </span>
    </div>
    <Widget v-if="store.showApp" @close="toggle(false)" :expanded="$props.expanded" />
  </div>
</template>
<style>
:host {
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  all: initial;
}

.prevent-style {
  font-family: var(--bs-body-font-family) !important;
  font-size: var(--bs-body-font-size) !important;
  font-weight: var(--bs-body-font-weight) !important;
  line-height: var(--bs-body-line-height) !important;
  color: var(--bs-body-color) !important;
  text-align: var(--bs-body-text-align) !important;
  -webkit-text-size-adjust: 100% !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  border-radius: 2rem;

  .widget-icon {
    padding: 5px;
    width: fit-content;
    position: relative;
    border-radius: 2rem;
    background-color: transparent;
  }
}
.with-background {
  background-color: var(--bs-body-bg) !important;

  .widget-icon {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
  }
}
</style>