import { ref, watchEffect, provide, Ref } from 'vue';

export function useVisibilityController(mobileLayout: Ref<boolean>) {
  
  const showConversation = ref<boolean>(false);
  const showList = ref<boolean>(true);

  const onUserClick = (): void => {
    showConversation.value = true;
    showList.value = !mobileLayout.value;
  };

  const goBackToList = (): void => {
    showConversation.value = false;
    showList.value = true;
  };

  const resetToDefaultLayout = (): void => {
    showConversation.value = true;
    showList.value = true;
  };

  watchEffect(() => {
    if (mobileLayout.value) {
      showConversation.value = false;
      showList.value = true;
    } else {
      resetToDefaultLayout();
    }
  });

  provide('showConversation', showConversation);
  provide('showList', showList);
  provide('onUserClick', onUserClick);
  provide('goBackToList', goBackToList);
  provide('resetToDefaultLayout', resetToDefaultLayout);
}
