import { default as axios } from "../axiosInstance";

// Define the structure of the response from the API.
interface AssignUrlResponse {
  data: {
    data: string; // Adjust this type if 'data' is not a string. Replace 'string' with the actual type if different.
  };
}

// Define the structure of the return value of the function.
interface AssignUrlResult {
  assignUrl: string | null;
}

/**
 * Function to get an assigned URL for a given file name.
 * @param fileName - The name of the file for which to get an assigned URL.
 * @returns A promise resolving to an object containing the assigned URL.
 */
export async function assignUrl(fileName: string): Promise<AssignUrlResult> {
  let assignUrl: string | null = null;

  try {
    const response: AssignUrlResponse = await axios.post(
      "/messages/media-signed-url",
      {
        filename: fileName,
      },
    );
    assignUrl = response.data.data;
  } catch (error: any) {
    // Using 'any' for the error type. Adjust it based on actual error structure if available.
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the entire error if the expected structure is not found.
  }

  return { assignUrl };
}
