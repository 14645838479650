<script>
import { ref, reactive, watch, onMounted } from "vue";
import { index as fetchClinics } from "@/use/repositories/chat/clinic/index.ts";

export default {
  emits: ['selectChatType'],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const clinics = ref([]);

    const filters = reactive({
      clinic: {},
      chat: false,
    });

    const hasActiveFilters = computed(() => filters.clinic?.name || filters.chat)

    const toggleMenu = () => isOpen.value = ! isOpen.value;

    const handleChange = (event) => filters.chat = event;

    const getChatType = () => {
      const hasChat = filters.chat;
      const hasClinic = Object.keys(filters.clinic).length;

      if (! hasClinic) return hasChat ? "by_my_unseen" : "by_unseen";

      return hasChat ? "by_my_unseen_filtered_by_clinic" : "by_unseen_filtered_by_clinic";
    };

    watch(
      () => filters,
      () => {
        const subChatType = getChatType();
        emit('selectChatType', 'unseen', subChatType, filters.clinic?.id);
      },
      { deep: true },
    );

    onMounted( async () => {
      const { data: { data } } = await fetchClinics({ action: 'by_user'})
      clinics.value = data;
    })

    return { isOpen, filters, clinics, handleChange, toggleMenu, hasActiveFilters };
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-items-center mb-3 px-3">
      <button
        class="btn p-0"
        type="button"
        @click="toggleMenu"
        aria-expanded="false"
      >
        <img src="@/assets/icons/filter.svg" alt="paperclip" width="30" />
      </button>
      <div class="d-flex justify-content-start">
        <template v-if="! hasActiveFilters">
          <span class="ms-2">{{ $t("No filter applied") }}</span>
        </template>
        <template v-else>
          <span v-if="filters.clinic?.name" 
            class="bg-violet text-light py-0 px-2 rounded cp mx-1 text-capitalize"
            @click="() => filters.clinic = {}">
            {{ filters?.clinic?.name?.toLowerCase() }}
            <img src="@/assets/icons/close.svg" alt="close" width="25" />
          </span>
          <span v-if="filters.chat" 
            class="bg-violet text-light py-0 px-2 rounded cp mx-1"
            @click="filters.chat = false">
            {{ $t('Conversations started') }}
            <img src="@/assets/icons/close.svg" alt="close" width="25" />
          </span>
        </template>
      </div>
    </div>

    <transition name="slide-down">
      <div v-if="isOpen" class="filter-container bg-light start-0 w-100 px-3">
        <h5 class="px-1">{{ $t("Additional filters") }}</h5>
        <SwitchToggle
          :modelValue="filters.chat"
          :label="$t('Conversations started')"
          switchId="conversationToggle"
          @change="handleChange($event)"
          class="my-3 px-1"
        />

        <SelectWithSearch
          v-model="filters.clinic"
          :options="clinics"
          :placeholder="$t('Type to search')"
          class="mb-3 px-1"
        />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.7s ease;
  overflow: hidden;
}
.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 150px;
  opacity: 1;
}
img {
    filter: brightness(100%);
  }

</style>
