import getAuthToken from "../../use/repositories/chat/getAuthToken";

interface Channel {
  name: string;
}

interface PusherAuthResponse {
  auth: string;
}

interface PusherAuthCallback {
  (error: Error | null, data?: PusherAuthResponse): void;
}

// Function to authorize Pusher channels
export function authorize(channel: Channel) {
  return {
    authorize: (socketId: string, callback: PusherAuthCallback) => {
      fetch(`${import.meta.env.VITE_API_CHAT_BASE_URL}/broadcasting/auth`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAuthToken()}`,
        }),
        body: JSON.stringify({
          socket_id: socketId,
          channel_name: channel.name,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Received ${res.status} from ${res.url}`);
          }
          return res.json();
        })
        .then((data: PusherAuthResponse) => {
          callback(null, data);
        })
        .catch((err) => {
          callback(new Error(`Error calling auth endpoint: ${err.message}`), {
            auth: "",
          });
        });
    },
  };
}
