import { default as axios } from "../axiosInstance";

interface ChannelResponse {
  data: {
    data: Channel;
  };
}

interface Channel {
  // Define the structure of the Channel object based on what is expected
  // Example fields (replace with actual fields as necessary):
  id: string;
  name: string;
  // Add other fields as required
}

export async function edit(
  id: string,
  action: string,
  value: any,
): Promise<{ channel: Channel }> {
  let channel: Channel = {} as Channel;

  try {
    const response: ChannelResponse = await axios.put(`/channels/${id}`, {
      action: action,
      value: value,
    });
    channel = response.data.data;
  } catch (error: any) {
    // Type assertion is used here for error typing, can be adjusted if you have a specific error structure
    throw error.response.data.errors;
  }

  return { channel };
}
