import { default as axios } from "../axiosInstance";

// Define the structure of the expected API response
interface StoreResponse<T> {
  data: T;
}

// Define the structure of the result that the function will return
interface StoreResult<T> {
  message: T;
}

/**
 * Function to store a new message using form data.
 * @param formData - The form data to be sent in the request. This is expected to be an instance of FormData.
 * @returns A promise resolving to an object containing the stored message data.
 */
export async function store<T = any>(
  formData: FormData,
): Promise<StoreResult<T>> {
  let message: T;

  try {
    const response: StoreResponse<T> = await axios.post("/messages", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    message = response.data;
  } catch (error: any) {
    // If the error response follows a known structure, handle it accordingly
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    }
    throw error; // Throw the full error if the expected structure is not found.
  }

  return { message };
}
