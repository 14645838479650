<script>
import { inject, computed } from "vue";
import { useMainStore } from "@/pinia/main.store";

export default {
  name: "TabNavigation",
  emits: ["selectChatType"],
  setup() {
    const { user } = useMainStore(); 
    const mobileLayout = inject("mobileLayout");
    const chatType = inject("chatType");
    const subChatType = inject("subChatType");
    const totalUnreadMessages = computed(() => user.totalUnreadMessages);

    return { mobileLayout, chatType, subChatType, totalUnreadMessages };
  },
};
</script>
<template>
<ul class="d-flex justify-content-center align-items-center flex-nowrap m-0 p-0 mb-2">
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: chatType === 'all_chat' }"
    :disabled="chatType === 'all_chat'"
    @click="$emit('selectChatType', 'all_chat', subChatType)"
  >
    <span
      v-if="chatType === 'all_chat' && subChatType === 'all' && totalUnreadMessages > 0"
      class="badge rounded-pill bg-violet position-absolute translate-middle"
    >
      {{ totalUnreadMessages }}
    </span>
    <small class="m-auto">{{ $t('To read') }}</small>
  </button>
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: chatType === 'by_last_reading' }"
    :disabled="chatType === 'by_last_reading'"
    @click="$emit('selectChatType', 'by_last_reading')"
  >
    <small class="m-auto">{{ $t('Already read') }}</small>
  </button>
  <button
    class="btn mx-3 position-relative d-flex"
    :class="{ active: chatType === 'favorite_chat' }"
    :disabled="chatType === 'favorite_chat'"
    @click="$emit('selectChatType', 'favorite_chat')"
  >
    <small class="m-auto">{{ $t('Favorites') }}</small>
  </button>
</ul>
<transition name="slide-down">
  <div class="d-flex justify-content-end px-3" v-if="chatType === 'all_chat'">
    <SwitchToggle
      :label="$t('Conversations started')"
      switchId="conversationToggle"
      @change="$emit('selectChatType', 'all_chat', $event ? 'personal' : 'all')"
      class="mx-4"
    />
  </div>
</transition>
</template>
<style lang="scss" scoped>
.slide-down-enter-active, .slide-down-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}
.slide-down-enter-from, .slide-down-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-down-enter-to, .slide-down-leave-from {
  max-height: 100px;
  opacity: 1;
}

ul {
  button {
    border-radius: 0.5rem;
    padding: 6px 9px 6px 9px;
    font-size: 14px;

    &:hover{
      border-color: #b5a3ca;
    }
    &.active {
      background-color: #f3e9ff;
      border-color: #b5a3ca;
    }
  }
}

.badge {
  left: 100%;
}
</style>
