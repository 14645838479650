import { useMainStore } from "../../../pinia/main.store";

// Define the return type
type AuthToken = string | undefined;

// Function to get auth token
const getAuthToken = (): AuthToken => {
  // Use the store directly
  const mainStore = useMainStore();

  // Return the auth token
  return mainStore.user.authToken;
};

export default getAuthToken;
