<script>
import { computed } from "vue";
import dayjs from "dayjs";
import { maxBy as _maxBy } from 'lodash';
export default {
  name: "ListItem",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    channel: {
      required: true,
    },
  },
  setup(props) {
    const getDateTimeLastMessage = (lastMessageAt) => {
      if (dayjs(lastMessageAt).startOf('day').isBefore(dayjs().startOf('day'))) {

        return dayjs(lastMessageAt).format("DD/MM/YYYY HH:mm")
      }
      
      return dayjs(lastMessageAt).format("HH:mm")
    };
    
    const unreadMessagesCount = computed(() => props.channel.messages.filter(message => ! message.isRead).length);

    const lastMessage = computed(() => _maxBy(props.channel.messages, 'created_at'));

    return { getDateTimeLastMessage, unreadMessagesCount, lastMessage };
  },
};
</script>

<template>
  <a
    href="javascript:void(0)"
    class="list-group-iem chat-item py-2 rounded-3"
    :class="{ active: $props.active }"
    aria-current="true"
  >
    <div class="d-flex w-100 align-items-center justify-content-between">
      <span class="d-flex mx-2">
        <img
          src="@/assets/icons/customer.svg"
          width="40"
          height="40"
          alt="customer"
          class="m-1 me-3 border border-1 rounded-circle p-2"
        />
        <span class="d-flex flex-column">
          <small>{{ $props.channel.chat_channelable.clinic.name }} - {{  $props.channel.name }}</small>
          <small class="fw-bold">
            {{ $props.channel.chat_channelable.customer.first_name }}
            {{ $props.channel.chat_channelable.customer.last_name }}
          </small>
          <small class="truncate text-muted">{{ lastMessage?.body || 'Foto' }}</small>
        </span>
      </span>
      <span class="d-flex flex-column mx-3">
        <span v-show="unreadMessagesCount" class="ms-auto badge rounded-pill bg-violet w-fit-content">
            {{ unreadMessagesCount }}
        </span>
        <small class="text-nowrap">{{ getDateTimeLastMessage($props.channel.last_message_at) }}</small>
      </span>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.active {
  background-color: #f3e9ff;
  border: none;
}
.chat-item {
  color: black;
  &:hover {
    transition: 0.5s;
    background-color: #f3e9ff;
  }
}

.w-fit-content {
  width: fit-content;
}

a {
  text-decoration: none !important;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
  font-style: italic;
}
</style>
