<script lang="ts">
import { ref, computed, watch, defineComponent, PropType, SetupContext } from 'vue';

interface Option {
  value: string | number | object;
  name: string;
}

interface Props {
  options: Option[];
  modelValue: string | number | object | null;
  placeholder: string;
}

export default defineComponent({
  name: 'SelectWithSearch',
  emits: ['update:modelValue'],
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Object] as PropType<string | number | object | null>,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Search...',
    },
  },
  setup(props: Props, { emit }: SetupContext) {
    const searchTerm = ref<string>('');
    const isOpen = ref<boolean>(false);
    const selectedOption = ref<Option | null>(null);

    const filteredOptions = computed<Option[]>(() => {
      if (! searchTerm.value) return props.options;

      return props.options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    });

    const selectOption = (option: Option): void => {
      selectedOption.value = option;
      searchTerm.value = option.name;
      isOpen.value = false;
      emit('update:modelValue', option);
    };

    const filterOptions = (): void => {
      if (!searchTerm.value) isOpen.value = false;
      else isOpen.value = true;
    };

    const openDropdown = (): void => {
      isOpen.value = true;
    };

    const handleBlur = (): void => {
      setTimeout(() => isOpen.value = false, 200);
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        selectedOption.value = props.options.find((option) => option.value === newValue) || null;
        searchTerm.value = selectedOption.value ? selectedOption.value.name : '';
      },
      { immediate: true }
    );

    return { searchTerm, isOpen, filteredOptions, selectOption, openDropdown, handleBlur, filterOptions };
  },
});
</script>

<template>
  <div>
    <div class="input-group flex-nowrap">
      <span class="input-group-text" id="addon-wrapping">{{ $t('Clinic') }}</span>
      <input v-model="searchTerm"
        type="text"
        :placeholder="placeholder"
        @focus="openDropdown"
        @blur="handleBlur"
        @input="filterOptions"
        class="form-control" 
        aria-label="Username" 
        aria-describedby="addon-wrapping">
    </div>
    <div v-if="isOpen" class="list-container">
      <ul class="list-group">
        <li 
          v-for="option in filteredOptions" 
          :key="option.value"
          @click="selectOption(option)"
          class="list-group-item list-group-item-action">
          <span class="text-capitalize">{{ option.name.toLowerCase() }}</span>
        </li>
        <li v-if="! filteredOptions.length" class="list-group-item">
          {{ $t("No result") }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.list-container {
  height: 8rem; 
  overflow: auto;
}
input:focus {
  box-shadow: none;
  border-color: #A58ABA;
}
</style>
