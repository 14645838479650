import { default as axios } from "../axiosInstanceWithAbort";

interface Channel {
  // Define the structure of the Channel object based on what is expected
  // Example fields (replace with actual fields as necessary):
  id: string;
  name: string;
  // Add other fields as required
}

interface IndexResponse {
  data: {
    data: Channel[];
  };
}

/**
 * @param action String
 * by_clinic_unseen - by_clinic_my_unseen - by_favorite - by_search -
 * @param search_term String (optional)
 * Required if action === by_search
 * @param page Number
 */
export async function index(
  action: string,
  page: number,
  search_term?: string,
): Promise<{ data: Channel[] }> {
  let data: Channel[] = [];

  try {
    const response: IndexResponse = await axios.get("/channels", {
      params: { action, page, search_term },
    });
    data = response.data.data;
  } catch (error: any) {
    // Type assertion is used here for error typing, can be adjusted if you have a specific error structure
    throw error.response.data.errors;
  }

  return { data };
}
