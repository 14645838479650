import { default as axios } from "../axiosInstanceWithAbort";

interface Channel {
  // Define the structure of the Channel object based on what is expected
  // Example fields (replace with actual fields as necessary):
  id: string;
  name: string;
  // Add other fields as required
}

interface IndexResponse {
  data: {
    data: Channel[];
  };
}

interface IndexParams { 
  [key: string]: any
}

/**
  * @param params - The query parameters to pass with the request.
  * @returns A promise resolving to an object containing the fetched data.
 */
export async function index(
  params: IndexParams,
): Promise<{ data: Channel[] }> {
  let data: Channel[] = [];

  try {
    const response: IndexResponse = await axios.get("/channels", { params });
    data = response.data.data;
  } catch (error: any) {
    // Type assertion is used here for error typing, can be adjusted if you have a specific error structure
    throw error.response.data.errors;
  }

  return { data };
}
