<template>
  <transition name="modal-fade">
    <div v-if="isVisible" class="custom-modal-backdrop">
      <div :class="['custom-modal', { fullscreen }]">
        <div class="custom-modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, defineComponent, watch } from 'vue';
import { useMainStore } from '@/pinia/main.store';

export default defineComponent({
  name: "CustomModal",
  props: {
    fullscreen: { type: Boolean, default: false }
  },
  setup() {
    const mainStore = useMainStore();
    const isVisible = ref(true);

    const showModal = () => isVisible.value = true;

    watch(() => mainStore.showApp, value => isVisible.value = value);

    return { isVisible, showModal };
  },
});
</script>

<style scoped lang="scss">

.custom-modal {
  border: 2px solid #725D82;
  border-radius: 1.5rem;
  background: white;
  width: 450px;
  height: 600px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.fullscreen {
    border: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    transition: all 0.3s ease;
  }

  .custom-modal-body {
    flex-grow: 1;
    overflow-y: auto;
    height: 100%;
  }
}

</style>
